import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../prismic/linkResolver'
import get from 'lodash/get'
import CaseTeaser from '../components/CaseTeaser'
import CategoryHeader from '../components/CategoryHeader'
import CasesGrid from '../components/CasesGrid'
import ParseSEOData from '../components/ParseSEOData'

export const query = graphql`
  query InspirationQuery($lang: String){
    prismic {
      allCases(lang: $lang, sortBy: rank_DESC) {
        edges {
          node {
            title
            description
            featured_image_portrait
            featured_detail_image
            collections {
              collection {
                ... on PRISMIC_Collection {
                  title
                }
              }
            }
            _meta {
              uid
              type
              lang
            }
          }
        }
      }
      allCategorys(lang: $lang, sortBy: title_ASC) {
        edges {
          node {
            title
            description
            _meta {
              uid
              type
              lang
            }
          }
        }
      }
      allInspirations(lang: $lang) {
        edges {
          node {
            title
            description
            meta_description
            meta_title
            share_image
            _meta {
              uid
              type
              lang
            }
          }
        }
      }
    }
  }
`


const Inspiration = ({data}) => {
  const doc = data.prismic.allInspirations.edges.slice(0,1).pop()
  if (!doc) return null;
  const categories = get(data,'prismic.allCategorys.edges')
  const inspirationData = get(data,'prismic.allInspirations.edges[0]')
  const inspirationMeta = get(inspirationData, 'node._meta');
  const cases = get(data, 'prismic.allCases.edges')
  const title = get(inspirationData,'node.title[0].text')
  let description = get(inspirationData,'node.description')
  if(description){
    description = RichText.render(description, linkResolver)
  }
  const cols = []

  cols.push(<CategoryHeader title={title} description={description} categories={categories} inspirationMeta={inspirationMeta} />)

  return(
    <>
      <ParseSEOData data={doc.node} />
      <StaticQuery query={query} render={data => {
        cases.map((caseData, index)=>{
          const node = get(caseData,'node')
          const title = get(node, 'title[0].text')
          const prismicImage = get(node,'featured_image_portrait')
          const detailPrismicImage = get(node,'featured_detail_image')
          const meta = get(node,'_meta')
          let description = get(node,'description')
          const collections = get(node, 'collections')
          if(description){
            description = RichText.render(description, linkResolver)
          }

          cols.push(<CaseTeaser title={title} prismicImage={prismicImage} detailPrismicImage={detailPrismicImage} meta={meta} key={index} description={description} collections={collections} />)
        })
        return <CasesGrid cols={cols} />
      }} />
    </>
  )
}

Inspiration.query = query

export default Inspiration